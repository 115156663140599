<template>
  <div>
    <TippCard hintID="Mitgliederverwaltung_Fristenüberwachung_1_V1" text="Text">
    </TippCard>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="start">
            <v-col cols="12" sm="12" md="7">
              <Subheader
                icon="mdi-binoculars"
                title="Überwachte Fristen"
                :actions="actions"
              >
              </Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="deadlines"
                  item-key="meta.id"
                  sort-by="sortKey"
                  :sort-desc="false"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                      >
                        <deadline-detail-card
                          :deadline="item"
                        ></deadline-detail-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <Subheader
                icon="mdi-timer-sand-complete"
                title="Bevorstehende Ablaufdaten"
              >
              </Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="ablaufdaten"
                  item-key="meta.id"
                  :items-per-page="10"
                  sort-by="ablaufdatum"
                  :sort-desc="true"
                  :group-by.sync="groupBy"
                  :group-desc.sync="groupDesc"
                  class="elevation-1 rounded"
                  max-height="800px auto"
                >
                  <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th :colspan="headers.length" style="height: 32px">
                      <v-icon left @click="toggle"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                      {{ getHeaderText(items[0].ablaufdatum) }}
                    </th>
                  </template></v-data-table
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <router-view />
  </div>
</template>

<script>
// import { db } from "@/firebase";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import DeadlineDetailCard from "@/components/member-management/deadlines/DeadlineDetailCard.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_DEADLINES } from "@/store/action-types.js";
import { MEMBER_MANAGEMENT_DEADLINES_create } from "@/data/permission-types.js";

export default {
  name: "member-management-deadline-monitoring-tab-overview",
  components: {
    Subheader,
    SupportTools,
    DeadlineDetailCard,
    TippCard,
  },
  data() {
    return {
      groupBy: "ablaufdatum",
      groupDesc: true,
      headers: [
        { text: "Name", value: "name" },
        { text: "Wert", value: "wert" },
        { text: "Ablaufdatum", value: "ablaufdatum" },
        { text: "Status", value: "status" },
      ],
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_DEADLINES_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-deadline-monitoring-deadline-new",
            });
          },
        },
      ],
      ablaufdaten: [
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
        {
          name: "Vorname Nachname",
          wert: "G26.3",
          ablaufdatum: "01.12.2021",
          status: "Gültig",
        },
      ],
    };
  },
  computed: {
    deadlines() {
      const deadlines = this.$store.state.memberManagement.deadlines;
      return deadlines.map((deadline) => {
        return {
          ...deadline,
        };
      });
    },
    upcomingDueDates() {
      return [];
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "deadlines", data: this.deadlines }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getDeadlines();
      this.getUpcomingDeadlines();
    },
    getDeadlines() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DEADLINES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    getUpcomingDeadlines() {},
    getHeaderText(date) {
      return date;
    },
  },
};
</script>
