<template>
  <div>
    <v-card
      :to="{
        name: 'member-management-deadline-monitoring-deadline-details',
        params: {
          itemId: deadline.meta.id,
        },
      }"
    >
      <v-row no-gutters>
        <v-col cols="9">
          <v-card-title v-if="!hideTitle" class="subheading">
            {{ deadline.title }}
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                v-for="(watcher, index) in deadline.watchers"
                :key="index"
                cols="sm"
              >
                <v-card
                  class="pa-2"
                  :color="`${getMaterialColor(watcher.color)} lighten-4`"
                  dark
                  :disabled="watcher.value == 0"
                  outlined
                >
                  <div
                    :class="[
                      'font-weight-medium text-h5 text-center text--darken-4',
                      `${getMaterialColor(watcher.color)}--text`,
                    ]"
                  >
                    {{ watcher.value }}
                  </div>
                  <div
                    :class="[
                      'font-weight-medium subtitle-2 text-center text--darken-4',
                      `${getMaterialColor(watcher.color)}--text`,
                    ]"
                  >
                    {{ watcher.title }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="3" class="d-flex justify-center align-center">
          <VueApexCharts
            type="donut"
            :options="timeSpendingChartConfig"
            :series="timeSpendingChartSeries"
            class="mx-2"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "deadline-detail-card",
  components: { VueApexCharts },
  props: {
    deadline: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeSpendingChartConfig: {
        chart: {
          height: 157,
          width: 130,
          parentHeightOffset: 0,
          type: "donut",
          sparkline: {
            enabled: true,
          },
        },
        labels: ["Gültig", "Nächste 30 Tage", "Abgelaufen"],
        colors: ["#CEE5CC", "#FAE1B8", "#F7CFD3"],
        stroke: { width: 0 },
        dataLabels: {
          enabled: false,
          formatter(val) {
            return `${Number.parseInt(val)}%`;
          },
        },
        legend: { show: false },
        tooltip: { theme: false },
        grid: { padding: { top: 0 } },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true,
                value: {
                  fontSize: "1.125rem",
                  color:
                    "rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity))",
                  fontWeight: 500,
                  offsetY: -15,
                  formatter(val) {
                    return `${Number.parseInt(val)}%`;
                  },
                },
                name: { offsetY: 20 },
                total: {
                  show: true,
                  fontSize: ".7rem",
                  label: "Gesamt",
                  color:
                    "rgba(var(--v-theme-on-background), var(--v-disabled-opacity))",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },

      timeSpendingChartSeries: [24, 5, 4],
    };
  },
  methods: {
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
        case "#F44336":
        case "red":
          return "red";
        case "#E91E63FF":
        case "#E91E63":
        case "pink":
          return "pink";
        case "#9C27B0FF":
        case "#9C27B0":
        case "purple":
          return "purple";
        case "#673AB7FF":
        case "#673AB7":
        case "deep-purple":
          return "deep-purple";
        case "#3F51B5FF":
        case "#3F51B5":
        case "indigo":
          return "indigo";
        case "#2196F3FF":
        case "#2196F3":
        case "blue":
          return "blue";
        case "#03A9F4FF":
        case "#03A9F4":
        case "light-blue":
          return "light-blue";
        case "#00BCD4FF":
        case "#00BCD4":
        case "cyan":
          return "cyan";
        case "#009688FF":
        case "#009688":
        case "teal":
          return "teal";
        case "#4CAF50FF":
        case "#4CAF50":
        case "green":
          return "green";
        case "#8BC34AFF":
        case "#8BC34A":
        case "light-green":
          return "light-green";
        case "#CDDC39FF":
        case "#CDDC39":
        case "lime":
          return "lime";
        case "#FFEB3BFF":
        case "#FFEB3B":
        case "yellow":
          return "yellow";
        case "#FFC107FF":
        case "#FFC107":
        case "amber":
          return "amber";
        case "#FF9800FF":
        case "#FF9800":
        case "orange":
          return "orange";
        case "#FF5722FF":
        case "#FF5722":
        case "deep-orange":
          return "deep-orange";
        case "#795548FF":
        case "#795548":
        case "brown":
          return "brown";
        case "#607D8BFF":
        case "#607D8B":
        case "blue-grey":
          return "blue-grey";
        case "#9E9E9EFF":
        case "#9E9E9E":
        case "grey":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
